import React, { useState, useEffect } from 'react';
import axiosCustom from '../../utilities/axiosCustom';
import { useNavigate, useParams } from 'react-router-dom'

import FavoritePosts from './FavoritePosts'
import FavoriteUsers from './FavoriteUsers'
import Posts from '../../pages/Posts';
import { Nav } from 'react-bootstrap';

function Favorites() {
  const { view } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Nav fill variant="pills">
        <Nav.Item>
          <Nav.Link onClick={()=>{navigate('/favorites/users')}} active={view === 'users'}>
            My Favorite Profiles
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link onClick={()=>{navigate('/favorites/posts')}} active={view === 'posts'}>
            My Favorite Posts
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <div>
        {view === 'users' ? <FavoriteUsers userId={localStorage.userID} /> :""}
        {view === 'posts' ? <FavoritePosts /> :""}
      </div>
    </>
  );
}

export default Favorites;