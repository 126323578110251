import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axiosCustom from '../../utilities/axiosCustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faMessage, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { Pill } from '@mantine/core';
import { Nav } from 'react-bootstrap';
import FavoriteUsers from '../favorites/FavoriteUsers.js';
import FavoriteButton from '../../components/FavoriteButton';

const possessiveName = name => name.endsWith('s') ? `${name}'` : `${name}'s`;

const PublicProfileNavigation = function({firstName}){
    const { id, view } = useParams();
    const navigate = useNavigate();
    return(
        <>
          <Nav fill variant="pills">
            <Nav.Item>
              <Nav.Link 
                active={!view}
                onClick={(event)=>{navigate(`/profile/${id}/${window.location.search}`)}}
              >
                Profile
              </Nav.Link>
            </Nav.Item>
            
            <Nav.Item>
              <Nav.Link 
                active={view === 'favorites'}
                onClick={(event)=>{navigate(`/profile/${id}/favorites/${window.location.search}`)}}
              >
                {`${possessiveName(firstName)} Favorites`}
              </Nav.Link>
            </Nav.Item>

          </Nav>
        </>
    )
}

function PublicProfile() {
  const { id, view } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    headline: '',
    aboutMe: '',
    workExperience: '',
    skills: '',
    languages: '',
    education: '',
    hourlyRate: '',
    cityName: '',
    stateName: '',
    stateId: ''
  });

  const loadFields = function(){
    axiosCustom.get('/api/user/'+( (id===undefined) ? localStorage.getItem('userID') : id ))
    .then((response) => {
        const data = response.data;
        var dataWithNullsRemoved = {};

        Object.keys(data.userObject).forEach(function(key){
            dataWithNullsRemoved[key] = ((data.userObject[key] === null) ? "" : data.userObject[key]);
        })
        setFormData(dataWithNullsRemoved);
    })
    .catch((error)=>{
        console.error('Error fetching user:', error);
    })
  }
  useEffect(loadFields, [id])

  const ProfileView = () => {
    return(
        <>
            <Row style={{marginTop: "20px"}}>
                <Col l="6" className='d-flex justify-content-end'>          
                    { !(id === undefined  || id === localStorage.getItem('userID') ) ? <FavoriteButton userId={id} /> : ""}
                    { !(id === undefined  || id === localStorage.getItem('userID') ) ? <Button href={'/messages/new?recipientId='+id} style={{marginLeft: "5px", marginRight: "5px"}} className="buttonWithOutline" variant="outline-primary">{`Message ${formData.firstName}   `}<FontAwesomeIcon icon={faMessage} /></Button> : ""}
                    { (parseInt(id) === parseInt(localStorage.getItem('userID')) && !window.location.search.includes('search')) ? <Button href='/profile/edit' style={{marginLeft: "5px", marginRight: "5px", float: "right"}} className="buttonWithOutline" variant="outline-primary">Edit Profile <FontAwesomeIcon icon={faPenToSquare} /></Button> : ""}
                </Col>
            </Row>

            <div style={{marginTop: '50px'}} className="row bioText">
                <div className="col-lg-4">
                    <img src={`/api/user/profile-picture/${( (id===undefined) ? localStorage.getItem('userID') : id )}`} width='160' />

                    <h2>{formData.firstName + " " + formData.lastName}</h2>
                    <h4>{formData.headline}</h4> 
                    {
                    formData.cityName.length > 0 ? <><h5>{formData.cityName + ", " + formData.stateName}</h5></>:""
                    }
                    <hr />

                    {
                    formData.accountType === 'client' ? "" : 
                    <>
                    {
                    formData.skills.length > 0 ? <h4>Skills</h4> : ""
                    }

                    {
                    formData.skills.split(',').map((skill, index)=>{
                    if(skill.length > 0){
                    return <Pill key={index}>{skill}</Pill>
                    }
                    })
                    }
                    <br />
                    </>
                    }
                </div>

                <div className="col-lg-4">
                    {
                        formData.aboutMe.length > 0 ? 
                        <><b>About Me</b><p dangerouslySetInnerHTML={{ __html: formData.aboutMe.replace(/\n/g, '<br />') }} /></>
                        :""
                    }
                    {
                        formData.workExperience.length > 0 && formData.accountType !== 'client'?
                        <><b>Work Experience</b><p dangerouslySetInnerHTML={{ __html: formData.workExperience.replace(/\n/g, '<br />') }} /></>
                        :""
                    }
                </div>
                <div className="col-lg-4">
                    {
                        formData.education.length > 0 && formData.accountType !== 'client' ? 
                        <><b>Education</b><p dangerouslySetInnerHTML={{ __html: formData.education.replace(/\n/g, '<br />') }} /></>
                        :""
                    }
                    {
                        formData.languages.length > 0 ? <><b>Languages</b><p>{formData.languages}</p></>:""
                    }
                    {
                        formData.hourlyRate.length > 0 && formData.accountType !== 'client' ? <><b>Hourly Rate</b><p>{formData.hourlyRate}</p></>:""
                    }
                </div>
            </div>
        </>
    )
  }
    return(
        <>
            <Row>
                {
                    (window.location.search.includes('search')) ? <Col l="6"><a onClick={()=>{navigate(-1)}} style={{float:'left'}} a href="#">Back to search</a></Col> : ""
                }
                {
                    (window.location.search.includes('favorites')) ? <Col l="6"><a onClick={()=>{navigate(-1)}} style={{float:'left'}} a href="#">Back to My Favorites</a></Col> : ""
                }
            </Row>
            <PublicProfileNavigation
                firstName = {formData.firstName}
            />
            {
                !view ? <ProfileView /> : ''
            }
            {
                view === 'favorites' ? <FavoriteUsers firstName={formData.firstName} userId={id} /> : ''
            }
        </>
    )
}

export default PublicProfile;
