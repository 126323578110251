import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Container, Nav,  } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import PostListItem from '../../components/PostListItem';
import axiosCustom from '../../utilities/axiosCustom';
import Posts from '../Posts';

import LocationInputQ from '../../components/LocationInputQ';

const PostsNavigation = function({view}){
    const navigate = useNavigate();
    return(
        <>
          <Nav fill variant="pills">
            <Nav.Item>
              <Nav.Link 
                active={!view}
                onClick={(event)=>{navigate(`/posts`)}}
              >
                Search Posts
              </Nav.Link>
            </Nav.Item>
            
            <Nav.Item>
              <Nav.Link 
                active={view === 'myPosts'}
                onClick={(event)=>{navigate(`/posts/my`)}}
              >
                My Posts
              </Nav.Link>
            </Nav.Item>

          </Nav>
        </>
    )
}

const SearchPostsView = () => {
    const location = useLocation(),
          navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setIsLoading] = useState(false);

    // Populate state with the query values.
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      setSearch(params.get('search') || '');
    }, [window.location]);

    // Update URL when state changes
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      if(search.length > 0){
        params.set('search', search);
      }
      navigate(`?${params.toString()}`, { replace: true });
    }, [search]);
    
    // Whenever the search query is edited by any of the components, run the search again.
    // useEffect(runSearch, [location.search]);

    const handleSearch = function(event){
        event.preventDefault();
        if(event.key === 'Enter' || event.type==='click') {
          runSearch();
        }
    }

    const runSearch = function(){
        setIsLoading(true);
        axiosCustom.get('/api/posts/search' + location.search)
        .then(response => {
            if (response.data) {
            setIsLoading(false);
            setSearchResults(response.data.data);
        }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error('Search error:', error);
        });
    }

    function SearchResults({ searchResults, loading }) {
        // useEffect(() => {
        // }, [searchResults]);
        
        if (loading) {
          return <h4 className='mx-auto'>Loading...</h4>;
        }
        if (searchResults.length === 0) {
          return <h5 className='mx-auto text-body-secondary'>No posts were found.</h5>;
        } else {
          return (
            <>
              {searchResults?.map((post, key)=>{
                  return <PostListItem
                      title={post.title} 
                      description={post.description}
                      id={post.id}
                      author= {post.firstName +" " +post.lastName }
                      key={key}
                      createdDateTime={post.createdDateTime}
                      appendToLink={'search'}
                  />
              })}
            </>
          );
        }
    }
  return(
    <>
      <Container>
        <Row className="mt-3">
          <Col className="mb-4" md={12} lg={3} style={{border: '1px solid right'}}>
            <b>Filters</b>
            <br />
            <b style={{float: 'left'}} >Location</b>
            <br />
            <LocationInputQ
              runSearch = {runSearch}
            />
            <br />
            
            <hr />
          </Col>
          
          <Col md={12} lg={9}>
            <Row className="w-100">
                <Col className="p-0" xs={11} sm={10}>
                  <Form.Control
                    name="search"
                    type="text"
                    placeholder="Search Job Posts"
                    value={search}
                    onChange={(e)=>{setSearch(e.target.value)}}
                  />
                </Col>

                <Col className="p-0" xs={1} sm={2}>
                  <Button id="button-addon2" onClick={handleSearch} variant="outline-primary"> Search </Button>
                </Col>
            </Row>

            <SearchResults searchResults={searchResults} loading={loading} />
            
          </Col>

        </Row>
      </Container>
    </>
  )
}

function SearchPosts({view}) {
    return (
      <>
        <PostsNavigation view={view} />
        {
          !view ? <SearchPostsView /> : <Posts />
        }
      </>
    );
}

export default SearchPosts;