import React, { useEffect, useState } from 'react';
import { Select } from '@mantine/core';
import axiosCustom from '../utilities/axiosCustom';

var LocationComponent = function( { updateSelectedCityId, locationInput, setLocationInput, suggestions, setSuggestions } ) {
  
    var findSuggestions = function(){
        if(locationInput.length >0){
            axiosCustom.get('/api/locations/search/' + locationInput)
            .then(response => {
                if (response.data.locations.length > 0) {
                    setSuggestions(response.data.locations);
                }
            })
            .catch(error => {
            console.error('Error:', error);
            });
        }
    }
  
    useEffect(findSuggestions, [locationInput]);
  
    var handleSearch = function(search, id){
        setLocationInput(search);
    }
    var handleSelectCity = function(_value, option){
      // option.value is the id of the selected city
      if(_value){
        updateSelectedCityId(option.value);
      }
    }
  
    return (
        <Select
            placeholder={ "City Name" }
            data={suggestions}
            searchValue={locationInput}
            onSearchChange={handleSearch}
            onChange={handleSelectCity}
            allowDeselect={false}
            searchable
            onClear={() => setLocationInput('')}
            limit={10}
            clearable
        />
        // <AutoComplete value={locationInput} suggestions={['a', 'b', 'c']} completeMethod={search} onChange={(e) => setLocationInput(e.value)}  />
    );
}

export default LocationComponent;