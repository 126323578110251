import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axiosCustom from '../../utilities/axiosCustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faMessage, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { Pill } from '@mantine/core';
import { Nav } from 'react-bootstrap';
import Posts from '../Posts';

const MembershipProfile = function({ view, setView }){
  return(
    <>
      <Nav fill variant="pills">
        <Nav.Item>
          <Nav.Link 
            active={view === 'profile'}
            onClick={(event)=>{event.preventDefault(); setView('profile')}}
          >
            My Profile
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            target="_blank"
            href="https://billing.stripe.com/p/login/00g8y95d73ZNf0A8ww"
            active={false}
           >
            Cancel My Membership.
          </Nav.Link>
        </Nav.Item>

      </Nav>
    </>
  )
}

const possessiveName = name => name.endsWith('s') ? `${name}'` : `${name}'s`;

function MyProfile() {
  const navigate = useNavigate();
  const [view, setView] = useState('profile');

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    headline: '',
    aboutMe: '',
    workExperience: '',
    skills: '',
    languages: '',
    education: '',
    hourlyRate: '',
    cityName: '',
    stateName: '',
    stateId: ''
  });

  var loadFields = async function(){

    await axiosCustom.get('/api/user/'+localStorage.getItem('userID'))
    .then((response) => {
        const data = response.data;
        var dataWithNullsRemoved = {};

        Object.keys(data.userObject).forEach(function(key){
            dataWithNullsRemoved[key] = ((data.userObject[key] === null) ? "" : data.userObject[key]);
        })
        setFormData(dataWithNullsRemoved);
    })
    .catch((error)=>{
        console.error('Error fetching user:', error);
    })
  }

  useEffect(function(){
    loadFields();
  }, [])

  return(
    <>
        {
          <>
            <MembershipProfile view = {view} setView = {setView} />
          </>
        }
        {
          view === 'posts' ? <Posts /> : ''
        }
        {
          view === 'profile' ?
            <>

            <Row style={{marginTop: "20px"}}>
              <Col l="6" className='d-flex justify-content-end'>          
                <Button href='/profile/edit' style={{marginLeft: "5px", marginRight: "5px", float: "right"}} className="buttonWithOutline" variant="outline-primary">Edit Profile <FontAwesomeIcon icon={faPenToSquare} /></Button>
              </Col>

            </Row>
            
            <div style={{marginTop: '50px'}} className="row bioText">
              <div className="col-lg-4">
                <img src={`/api/user/profile-picture/${localStorage.getItem('userID')}`} width='160' />

                <h2>{formData.firstName + " " + formData.lastName}</h2>
                 <h4>{formData.headline}</h4> 
                {
                  formData.cityName.length > 0 ? <><h5>{formData.cityName + ", " + formData.stateName}</h5></>:""
                }
                <hr />
                
                {
                  formData.accountType === 'client' ? "" : 
                  <>
                  {
                    formData.skills.length > 0 ? <h4>Skills</h4> : ""
                  }

                    {
                      formData.skills.split(',').map((skill, index)=>{
                        if(skill.length > 0){
                          return <Pill key={index}>{skill}</Pill>
                        }
                      })
                    }
                    <br />
                  </>
                }
                
              </div>
              <div className="col-lg-4">
                {
                formData.aboutMe.length > 0 ? 
                <><b>About Me</b><p dangerouslySetInnerHTML={{ __html: formData.aboutMe.replace(/\n/g, '<br />') }} /></>
                :""
                }
                {
                  formData.workExperience.length > 0 && formData.accountType !== 'client'?
                    <><b>Work Experience</b><p dangerouslySetInnerHTML={{ __html: formData.workExperience.replace(/\n/g, '<br />') }} /></>
                    :""
                }

              </div>
              <div className="col-lg-4">
                {
                  formData.education.length > 0 && formData.accountType !== 'client' ? 
                    <><b>Education</b><p dangerouslySetInnerHTML={{ __html: formData.education.replace(/\n/g, '<br />') }} /></>
                    :""
                }

                {
                  formData.languages.length > 0 ? <><b>Languages</b><p>{formData.languages}</p></>:""
                }

                {
                  formData.hourlyRate.length > 0 && formData.accountType !== 'client' ? <><b>Hourly Rate</b><p>{formData.hourlyRate}</p></>:""
                }

              </div>
                
            </div>
            </>
            :
            ''
  }
    </>
  )

}

export default MyProfile;
